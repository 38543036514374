<template>
  <div id="app" class="m-0 p-0">
    <b-navbar toggleable variant="dark" type="dark">
      <b-navbar-brand href="#">
        MS Sample Notice System
      </b-navbar-brand>

      <b-navbar-toggle target="navbar-toggle-collapse">
        <template v-slot:default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
          <b-icon v-else icon="chevron-bar-down"></b-icon>
        </template>
      </b-navbar-toggle>

      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#">Install to Home Screen</b-nav-item>
          <b-nav-item href="#">About</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <a
      style="cursor:pointer"
      :style="{ display: installBtn }"
      @click="installer()"
    >
      <h1>Update Available! Click to Install</h1>
    </a>
    <p class="mt-2">
      View the most recent bacteriological results for a Public Water System.
    </p>
    <div class="mb-2">
      <b-form-checkbox
        v-if="notificationsSupported"
        :disabled="buttonDisabled || !valid_pws_id"
        v-model="notificationsButtonOn"
        @change="toggleSubscription"
        name="check-button"
        size="lg"
        switch
      >
        Receive alerts for new results.
      </b-form-checkbox>
    </div>
    <div style="text-align: center">
      <b-card
        class="mx-auto"
        style="max-width: 30rem;"
        bg-variant="light"
        text-variant="dark"
        title="Public Water System"
      >
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group
            id="fs-pws-id"
            class="text-center"
            label="Enter a PWS ID (7 digits)"
            label-form="pws-id"
          >
            <b-form-input
              id="pws-id"
              style="text-align: center;"
              v-model="pws_id"
              type="number"
              :formatter="formatPwsId"
              no-wheel
              placeholder=""
              list="pws-datalist"
              @focus="clearPws"
              @keydown.enter="$event.target.blur()"
            ></b-form-input>
            <b-form-datalist
              id="pws-datalist"
              :options="pws_ids"
            ></b-form-datalist>
            <!--
            <datalist id="pws-datalisttt">
              <option v-bind:key="pws" v-for="pws in pws_ids">{{ pws }}</option>
            </datalist>
            -->
          </b-form-group>
          <div class="mt-2">
            <span>{{ pws_name_msg }}</span>
          </div>
          <div v-if="show_remove_button">
            <b-modal
              id="remove-pws-modal"
              title="Confirm Removal"
              @ok="onRemovePws"
              >Are you sure you want to remove {{ pws_id }} from your
              history?</b-modal
            >
            <b-button
              block
              class="mt-2"
              v-b-modal.remove-pws-modal
              variant="outline-dark"
            >
              <span>Remove PWS</span>
            </b-button>
          </div>
          <b-button block class="mt-2" @click="fetchSamples" variant="dark">
            <span v-show="!loading_results">
              {{ sample_results ? 'Reload' : 'Load' }} Results
            </span>
            <b-spinner small v-show="loading_results"></b-spinner>
          </b-button>
        </b-form>
      </b-card>
    </div>
    <b-alert
      class="my-4 mx-auto"
      style="max-width: 30rem;"
      v-model="show_data_error_alert"
      variant="danger"
      dismissible
    >
      Error Fetching Sample Results
    </b-alert>
    <div v-show="pws_id_v && !show_data_error_alert">
      <h5 class="mt-3">
        Bacteriological Results
      </h5>
      <b-button variant="dark" v-b-modal.modal-legend size="sm">View Legend</b-button>
      <b-modal id="modal-legend" ok-variant="dark" ok-only centered title="Sample Result Legend">
        <div class="text-left">
          <h5>Sample Results (Total and Ecoli)</h5>
            <b-row class="mb-1 ml-2 text-left">
              <b-col cols="2"><b>A</b></b-col>
              <b-col cols="2" class="text-left">Absent</b-col>
            </b-row>
            <b-row class="mb-1 ml-2 text-left">
              <b-col cols="2"><b>P</b></b-col>
              <b-col cols="2" class="text-left">Present</b-col>
            </b-row>
          <h5>Sample Purpose Codes</h5>
            <b-row class="mb-1 ml-2 text-left">
              <b-col cols="2"><b>RT</b></b-col>
              <b-col cols="2" class="text-left">Routine</b-col>
            </b-row>
            <b-row class="mb-1 ml-2 text-left">
              <b-col cols="2"><b>RP</b></b-col>
              <b-col cols="2" class="text-left">Repeat</b-col>
            </b-row>
            <b-row class="mb-1 ml-2 text-left">
              <b-col cols="2"><b>SP</b></b-col>
              <b-col cols="2" class="text-left">Special</b-col>
            </b-row>
          <h5>Result Row Styling</h5>
          <p class="bg-warning m-1 p-1 text-center">Rejected Sample</p>
          <p class="bg-danger m-1 p-1 text-center">Bacteria Present Sample</p>
        </div>
      </b-modal>
      <div class="mt-2">
      <span v-if="sample_results"
      ><strong>{{ sample_results.length }}</strong> most recent samples</span
      >
      </div>
      <b-table
        class="mt-3"
        small
        striped
        hover
        bordered
        responsive
        head-variant="dark"
        v-show="sample_results"
        :fields="sample_fields"
        :items="sample_results"
        :tbody-tr-class="rowClass"
      >
        <template #cell(sample_point)="row">
          <b-button @click="row.toggleDetails">{{
            row.item.sample_point
          }}</b-button>
        </template>
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Sample ID:</b></b-col>
              <b-col class="text-sm-left">{{ row.item.sample_id }}</b-col>
            </b-row>
            <b-row class="mb-2" v-if="row.item.reject_reason_cd.trim()">
              <b-col sm="3" class="text-sm-right"><b>Reject Reason:</b></b-col>
              <b-col class="text-sm-left">{{ row.item.reject_reason_cd }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Facility ID:</b></b-col>
              <b-col class="text-sm-left">{{ row.item.st_asgn_ident_cd }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Collector:</b></b-col>
              <b-col class="text-sm-left">{{ row.item.informal_collector }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"
                ><b>Facility Location:</b></b-col
              >
              <b-col sm="3" class="text-sm-left">{{ row.item.directions_text }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"
                ><b>Sample Point ID:</b></b-col
              >
              <b-col class="text-sm-left">{{ row.item.sample_point }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"
                ><b>Sample Point Location:</b></b-col
              >
              <b-col class="text-sm-left">{{ row.item.description_text }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"
                ><b>Free Chlorine:</b></b-col
              >
                <b-col class="text-sm-left">
                  <span v-if="row.item.ff_chlor_res_msr">
                    {{ toFixed(row.item.ff_chlor_res_msr, 2) }} ppm
                  </span>
                </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"
                ><b>Total Chlorine:</b></b-col
              >
              <b-col class="text-sm-left">
                <span v-if="row.item.fldtot_chl_res_msr">
                  {{ toFixed(row.item.fldtot_chl_res_msr, 2) }} ppm
                </span>

              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"
                ><b>Sample Point Note 1:</b></b-col
              >
              <b-col class="text-sm-left">{{ row.item.note_1 }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"
                ><b>Sample Point Note 2:</b></b-col
              >
              <b-col class="text-sm-left">{{ row.item.note_2 }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"
                ><b>Sample Point Note 3:</b></b-col
              >
              <b-col class="text-sm-left">{{ row.item.note_3 }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Date Received:</b></b-col>
              <b-col class="text-sm-left">{{ row.item.date_received }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Datetime Edited:</b></b-col>
              <b-col class="text-sm-left">{{ row.item.date_edited }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Sample Comments:</b></b-col>
              <b-col class="text-sm-left">{{ row.item.comment_text }}</b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
      <p v-show="!sample_results" class="mt-4">
        <strong>No Results Found</strong>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      installBtn: "none",
      installer: undefined,
      notificationsSupported: false,
      notificationsEnabled: false,
      notificationsButtonOn: false,
      buttonDisabled: false,
      serviceWorkerRegistration: null,
      show_data_error_alert: false,
      subscription: null,
      push_message: null,
      pws_id: null,
      pws_ids: [],
      pws_id_v: null,
      pws_name: null,
      sample_fields: [
        { key: "sample_point", sortable: true },
        { key: "test_reason", sortable: true, label: "Purpose" },
        { key: "tc_result", sortable: true, label: "Total" },
        { key: "ecoli_result", sortable: true, label: "Ecoli" },
        { key: "dt_collected", sortable: true, label: "DT Collected" }
      ],
      sample_results: null,
      loading_results: false,
      searching_pws: false
    }
  },
  created() {
    console.log("app created")
    if ("Notification" in window && "serviceWorker" in navigator) {
      this.notificationsSupported = true
    }
    // get public key from server
    this.axios
      .get(`${process.env.VUE_APP_API_PATH}/subscription`)
      .then((res) => {
        // console.log(res)
        localStorage.setItem("applicationServerPublicKey", res.data.public_key)
      })
      .catch((error) => {
        console.error(error)
      })

    // create random ID if we don't have it
    // used to update subscriptions
    if (!localStorage.random_id) {
      localStorage.random_id = this.getRandomString(20)
    }

    let installPrompt

    /*
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      installPrompt = e
      this.installBtn = 'block'
    })
     */

    this.installer = () => {
      this.installBtn = "none"
      installPrompt.prompt()
      installPrompt.userChoice.then((result) => {
        if (result.outcome === "accepted") {
          // console.log('Install accepted!')
        } else {
          // console.log('Install denied!')
        }
      })
    }
  },
  mounted() {
    console.log("app mounted")
    if (localStorage.pws_id) {
      console.log("loaded pws_id from storage")
      this.pws_id = localStorage.pws_id
      this.pws_id_v = this.pws_id
    }
    if (localStorage.pws_name) {
      console.log("loaded pws_name from storage")
      this.pws_name = localStorage.pws_name
    }
    if (localStorage.pws_ids) {
      this.pws_ids = JSON.parse(localStorage.getItem("pws_ids"))
    }
    // check for existing subscription
    if (navigator.serviceWorker) {
      this.findSubscription().then((sub) => {
        if (sub === null) {
          console.log("no active subscription found on the client", sub)
          this.buttonDisabled = false
          this.notificationsEnabled = false
          this.notificationsButtonOn = false
        } else {
          console.log("Active subscription found", sub)
          // retrieve user info from API
          this.buttonDisabled = false
          this.notificationsEnabled = true
          this.notificationsButtonOn = true
          this.subscription = sub
        }
      })
    }
    // get samples for pws
    if (this.pws_id) {
      this.fetchSamples()
    }
  },
  watch: {
    pws_id: function(newVal) {
      if (newVal.length === 7) {
        this.pws_id_v = ""
        this.fetchPwsName().then(() => {
          if (this.pws_name) {
            console.log("match!!! " + this.pws_name)
            this.pws_id_v = newVal
            this.fetchSamples()
          }
        })
      }
    }
  },
  computed: {
    pws_name_msg: function() {
      if (this.pws_name) {
        return "PWS Name: " + this.pws_name
      } else if (
        this.pws_id &&
        this.pws_id.length == 7 &&
        !this.searching_pws
      ) {
        return "PWS Not Found"
      } else {
        return "---"
      }
    },
    show_remove_button: function() {
      return this.pws_ids.length > 1
    },
    valid_pws_id: function() {
      return this.pws_id && (this.pws_id.length == 7)
    }
  },
  methods: {
    toFixed(value, precision) {
      let ret = ''
      if (value) {
        ret = value.toFixed(precision)
      }
      return ret
    },
    getRandomString(length) {
      var randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      var result = ""
      for (var i = 0; i < length; i++) {
        result += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        )
      }
      return result
    },
    rowClass(item, type) {
      if (!item || type !== "row") return
      if (item.tc_result === "P") return "table-danger"
      if (item.ecoli_result === "P") return "table-danger"
      if (item.reject_reason_cd.trim()) return "table-warning"
    },
    onRemovePws() {
      this.removePws(this.pws_id)
    },
    onSubmit() {
      console.log("submit")
      return false
    },
    clearPws() {
      this.pws_id = ""
      this.pws_id_v = ""
      this.pws_name = ""
      this.sample_results = null
    },
    formatPwsId(e) {
      return String(e).substring(0, 7)
    },
    fetchSamples() {
      // get samples
      this.show_data_error_alert = false
      this.loading_results = true
      this.axios
        .get(`${process.env.VUE_APP_API_PATH}/getsamples`, {
          params: { pws_id: this.pws_id }
        })
        .then((res) => {
          //console.log(res.data.results)
          this.loading_results = false
          if (res.data.results) {
            this.sample_results = res.data.results
            if (this.sample_results.length == 0) {
              this.sample_results = null
            }
          } else {
            this.sample_results = null
          }
        })
        .catch((error) => {
          this.show_data_error_alert = true
          this.loading_results = false
          console.error(error)
        })
    },
    fetchPwsName() {
      // get pws_name
      this.searching_pws = true
      return this.axios
        .get(`${process.env.VUE_APP_API_PATH}/getpws`, {
          params: { pws_id: this.pws_id }
        })
        .then((res) => {
          // console.log(res.data.pws_name)
          this.searching_pws = false
          if (res.data.pws_name) {
            this.pws_name = res.data.pws_name
            this.addPws(this.pws_id, res.data.pws_name)
          } else {
            this.pws_name = null
          }
        })
        .catch((error) => {
          this.searching_pws = false
          console.error(error)
        })
    },
    addPws(pws_id, pws_name) {
      localStorage.pws_id = pws_id
      localStorage.pws_name = pws_name
      if (this.pws_ids.indexOf(pws_id) == -1) {
        this.pws_ids.push(pws_id)
      }
    },
    removePws(pws_id) {
      const index = this.pws_ids.indexOf(pws_id)
      if (index > -1) {
        this.pws_ids.splice(index, 1)
      }
      localStorage.setItem("pws_ids", JSON.stringify(this.pws_ids))
      this.pws_id = ""
      this.pws_name = ""
    },
    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/")
      const rawData = window.atob(base64)
      const outputArray = new Uint8Array(rawData.length)
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    },
    toggleSubscription() {
      if (this.notificationsSupported) {
        //this.buttonDisabled = true
        // Find out if we need to create a subscription or delete it
        if (!this.notificationsEnabled) {
          // Ask permission and when granted, create new subscription
          console.log("Notification.permission is: " + Notification.permission)
          Notification.requestPermission().then((result) => {
            // if granted, create new subscription
            if (result === "granted") {
              // in case this doesn't work out
              this.notificationsButtonOn = false
              this.createSubscription()
                .then((sub) => {
                  console.log("subscription created on the client", sub)
                  this.subscription = sub
                  this.notificationsButtonOn = true
                  // store new subscription on the server
                  return this.axios.post(
                    `${process.env.VUE_APP_API_PATH}/subscribe`,
                    {
                      subscription: this.subscription,
                      pws_id: this.pws_id,
                      client_id: localStorage.random_id
                    }
                  )
                })
                .then(() => {
                  this.showNotification()
                  this.buttonDisabled = false
                  this.notificationsEnabled = true
                })
            } else {
              console.log("User did not granted permission")
              this.notificationsButtonOn = false
            }
          })
        } else {
          // Destroy subscription
          console.log("Disable subscription")
          if (this.subscription !== null) {
            // destroy on the server
            this.axios
              .post(`${process.env.VUE_APP_API_PATH}/unsubscribe`, {
                endpoint: this.subscription.endpoint,
                client_id: localStorage.random_id
              })
              // unsubscribe on the client
              .then(() => this.subscription.unsubscribe())
              .then(() => {
                // update the data
                this.notificationsEnabled = false
                this.notificationsButtonOn = false
                this.buttonDisabled = false
                this.subscription = null
              })
          }
        }
      }
    },
    createSubscription() {
      console.log("ask for active service worker registration")
      if (this.serviceWorkerRegistration === null) {
        return navigator.serviceWorker.ready // returns a Promise, the active SW registration
          .then((swreg) => {
            this.serviceWorkerRegistration = swreg
            return this.subscribe(this.serviceWorkerRegistration)
          })
      } else {
        return this.subscribe(this.serviceWorkerRegistration)
      }
    },
    getSubscription(swreg) {
      console.log("ask for available subscription")
      return swreg.pushManager.getSubscription()
    },
    subscribe(swreg) {
      console.log("create new subscription for this browser on this device")
      // create new subscription for this browser on this device
      // const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY
      const vapidPublicKey = localStorage.getItem("applicationServerPublicKey")
      console.log("swreg: " + swreg)
      console.log(vapidPublicKey)
      const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey)
      console.log(convertedVapidPublicKey)
      // return the subscription promise, we chain another then where we can send it to the server
      return swreg.pushManager.subscribe({
        userVisibleOnly: true,
        // This is for security. On the backend, we need to do something with the VAPID_PRIVATE_KEY
        // that you can find in .env to make this work in the end
        applicationServerKey: convertedVapidPublicKey
      })
    },
    findSubscription() {
      console.log("get active service worker registration")
      return navigator.serviceWorker.ready.then((swreg) => {
        console.log("haal active subscription op")
        this.serviceWorkerRegistration = swreg
        return this.getSubscription(this.serviceWorkerRegistration)
      })
    },
    showNotification() {
      this.serviceWorkerRegistration.showNotification("You are Subscribed", {
        body: "You will receive notifications for new sample results.",
        icon: "/img/icons/android-chrome-192x192.png",
        // image: '/img/autumn-forest.png',
        vibrate: [300, 200, 300]
        // badge: '/img/icons/plint-badge-96x96.png'
      })
    },
    dosubscribe() {
      console.log("subscribe " + this.subscribe)
      console.log(self)
      if (!self.pushmanager) {
        this.push_message = "push notification not supported."
        return
      }
      if (this.subscribe) {
        this.subscribeuser()
      }
    }
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
}
</style>
